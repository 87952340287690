import { getAnalytics, logEvent } from "firebase/analytics";
import { useCallback } from "react";
import { useAnalytics } from "reactfire";

const debugMode = false;
const devEnv = process.env.NODE_ENV === "development";

export const useLogging = () => {
  const analytics = useAnalytics();

  const log = useCallback(
    (eventName: string, eventParams: any) => {
      if (debugMode || !devEnv) {
        logEvent(analytics, eventName, {
          source: "web",
          ...eventParams,
        });
      } else {
        console.debug("logging", eventName, eventParams);
      }
    },
    [analytics]
  );

  return log;
};

export const log = (eventName: string, eventParams: any) => {
  if (debugMode || !devEnv) {
    logEvent(getAnalytics(), eventName, eventParams);
  } else {
    console.debug("logging", eventName, eventParams);
  }
};
