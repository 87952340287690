import { getAnalytics, setUserId } from "firebase/analytics";
import { useEffect } from "react";
import { useUser } from "reactfire";

export const useUserLogger = () => {
  const { data: user } = useUser();
  const userId = user?.uid;

  useEffect(() => {
    const analytics = getAnalytics();
    if (userId) {
      setUserId(analytics, userId);
    } else {
      setUserId(analytics, null);
    }
  }, [userId]);
};
