import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import "mapbox-gl/dist/mapbox-gl.css";
import React from "react";
import { createRoot } from "react-dom/client";
import { FirebaseAppProvider } from "reactfire";
import App from "src/App";
import { firebaseConfig, sentryConfig } from "src/config";
import "src/i18n/config";
import "src/index.css";

if (process.env.NODE_ENV !== "development") {
  Sentry.init({
    dsn: sentryConfig.dsn,
    integrations: [new BrowserTracing()],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
}

const container = document.querySelector("#root");
const root = createRoot(container!);
root.render(
  <FirebaseAppProvider firebaseConfig={firebaseConfig}>
    <App />
  </FirebaseAppProvider>
);
