export const HOME_ROUTE = {
  path: "/",
};

export const SIGN_IN_ROUTE = {
  path: "/signin",
};

export const SIGN_UP_ROUTE = {
  path: "/signup",
};

export const PASSWORD_RESET_ROUTE = {
  path: "/passwordreset",
};

export const TEST_ROUTE = {
  path: "/test",
};

export const USER_ADVENTURES_ROUTE = {
  path: "/user/:user_id/adventures",
  constructor: (userId: string) => `/user/${userId}/adventures`,
};

export const ADVENTURE_ROUTE = {
  path: "/adventure/:adventure_id",
  constructor: (adventureId: string) => `/adventure/${adventureId}`,
};

export const SUPPORTED_REGIONS_ROUTE = {
  path: "/regions",
};

export const FEEDBACK_ROUTE = {
  path: "/feedback",
};
