// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html {
  height: 100%;
  width: 100%;
  overflow-x: hidden;
  overflow-y: hidden;
}

body {
  margin: 0;
  height: 100%;
  width: 100%;
  overflow-x: hidden;
  overflow-y: hidden;
}

#root {
  height: 100%;
  width: 100%;
}

.new_waypoint_marker {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  cursor: pointer;
  background-color: #faa734;
  border: 3px solid white;
}

.map_popup {
  will-change: inherit;
}

.cursor_popup .mapboxgl-popup-content {
  background-color: transparent;
  box-shadow: none;
}

.cursor_popup .mapboxgl-popup-tip {
  border-top-color: transparent;
}

.loading {
  opacity: 0.2;
}
.loading.loading_shown {
  opacity: 0.8;
  transition: opacity 3s ease-out;
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,WAAW;EACX,kBAAkB;EAClB,kBAAkB;AACpB;;AAEA;EACE,SAAS;EACT,YAAY;EACZ,WAAW;EACX,kBAAkB;EAClB,kBAAkB;AACpB;;AAEA;EACE,YAAY;EACZ,WAAW;AACb;;AAEA;EACE,UAAU;EACV,WAAW;EACX,kBAAkB;EAClB,eAAe;EACf,yBAAyB;EACzB,uBAAuB;AACzB;;AAEA;EACE,oBAAoB;AACtB;;AAEA;EACE,6BAA6B;EAC7B,gBAAgB;AAClB;;AAEA;EACE,6BAA6B;AAC/B;;AAEA;EACE,YAAY;AACd;AACA;EACE,YAAY;EACZ,+BAA+B;AACjC","sourcesContent":["html {\n  height: 100%;\n  width: 100%;\n  overflow-x: hidden;\n  overflow-y: hidden;\n}\n\nbody {\n  margin: 0;\n  height: 100%;\n  width: 100%;\n  overflow-x: hidden;\n  overflow-y: hidden;\n}\n\n#root {\n  height: 100%;\n  width: 100%;\n}\n\n.new_waypoint_marker {\n  width: 8px;\n  height: 8px;\n  border-radius: 50%;\n  cursor: pointer;\n  background-color: #faa734;\n  border: 3px solid white;\n}\n\n.map_popup {\n  will-change: inherit;\n}\n\n.cursor_popup .mapboxgl-popup-content {\n  background-color: transparent;\n  box-shadow: none;\n}\n\n.cursor_popup .mapboxgl-popup-tip {\n  border-top-color: transparent;\n}\n\n.loading {\n  opacity: 0.2;\n}\n.loading.loading_shown {\n  opacity: 0.8;\n  transition: opacity 3s ease-out;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
