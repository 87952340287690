import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useLogging } from "./useLogging";

export const useRouteLogger = () => {
  const log = useLogging();
  const location = useLocation();

  useEffect(() => {
    log("page_view", { location: location.pathname });
  }, [location.pathname]);
};
