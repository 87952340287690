import React, { lazy, Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import {
  ADVENTURE_ROUTE,
  FEEDBACK_ROUTE,
  HOME_ROUTE,
  PASSWORD_RESET_ROUTE,
  SIGN_IN_ROUTE,
  SIGN_UP_ROUTE,
  SUPPORTED_REGIONS_ROUTE,
  TEST_ROUTE,
  USER_ADVENTURES_ROUTE,
} from "src/components/nav/routes";
import { useRouteLogger } from "src/hooks/useRouteLogger";
import { useUserLogger } from "src/hooks/useUserLogger";
import LoadingPage from "../common/LoadingPage";

const Home = lazy(
  () => import(/* webpackChunkName:'home' */ "src/components/home/Home")
);
const SignIn = lazy(
  () => import(/* webpackChunkName:'signin' */ "src/components/auth/SignIn")
);
const SignUp = lazy(
  () => import(/* webpackChunkName:'signup' */ "src/components/auth/SignUp")
);
const PasswordReset = lazy(
  () =>
    import(
      /* webpackChunkName:'passwordreset' */ "src/components/auth/PasswordReset"
    )
);
const UserAdventures = lazy(
  () =>
    import(
      /* webpackChunkName:'adventures' */ "src/components/user/adventures/UserAdventures"
    )
);
const Adventure = lazy(
  () =>
    import(
      /* webpackChunkName:'adventure' */ "src/components/adventures/Adventure"
    )
);
const SupportedRegions = lazy(
  () =>
    import(
      /* webpackChunkName:'supportedregions' */ "src/components/map/Regions/SupportedRegions"
    )
);
const Test = lazy(
  () => import(/* webpackChunkName:'test' */ "src/components/Test")
);

const Feedback = lazy(
  () =>
    import(/* webpackChunkName:'feedback' */ "src/components/feedback/Feedback")
);

export const AppRoutes = () => {
  useRouteLogger();
  useUserLogger();

  return (
    <Suspense fallback={<LoadingPage />}>
      <Routes>
        <Route path={HOME_ROUTE.path} element={<Home />} />
        <Route path={SIGN_IN_ROUTE.path} element={<SignIn />} />
        <Route path={SIGN_UP_ROUTE.path} element={<SignUp />} />
        <Route path={PASSWORD_RESET_ROUTE.path} element={<PasswordReset />} />
        <Route path={USER_ADVENTURES_ROUTE.path} element={<UserAdventures />} />
        <Route path={ADVENTURE_ROUTE.path} element={<Adventure />} />
        <Route
          path={SUPPORTED_REGIONS_ROUTE.path}
          element={<SupportedRegions />}
        />
        <Route path={FEEDBACK_ROUTE.path} element={<Feedback />} />
        <Route path={TEST_ROUTE.path} element={<Test />} />
      </Routes>
    </Suspense>
  );
};
