import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import enTranslation from "./en/translation.json";
import frTranslation from "./fr/translation.json";

export const languages = {
  en: { name: "EN" },
  fr: { name: "FR" },
};

export const resources = {
  en: {
    translation: enTranslation,
  },
  fr: {
    translation: frTranslation,
  },
} as const;

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: "en",
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources,
    detection: {
      order: ["querystring", "navigator"],
      lookupQuerystring: "lng",
    },
  });
