const emptyStyle = "mapbox/empty-v8";
const outdoorsStyle = "louiseugene/cl3iiqumm000215qmvuldmxmd";
const satelliteStyle = "louiseugene/cl7gd406e001f15p8qm3i4w46";
// mapbox/outdoors-v11
// mapbox/satellite-v9

export const mapboxConfig = {
  styles: { emptyStyle, outdoorsStyle, satelliteStyle },
  token:
    "pk.eyJ1IjoibG91aXNldWdlbmUiLCJhIjoiY2w4NGNzaTIzMGVpMTNub2UydzIzdTBnMCJ9.aGQMy_BR1d2IQaipAGVmsw",
};

export const firebaseConfig = {
  apiKey: "AIzaSyAbsCxCB2ohuCeI2y7SEclZ_4ZFIwG1ghM",
  authDomain: "actionpacked-c792e.firebaseapp.com",
  databaseURL:
    "https://actionpacked-c792e-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "actionpacked-c792e",
  storageBucket: "actionpacked-c792e.appspot.com",
  messagingSenderId: "895709954337",
  appId: "1:895709954337:web:d8d69d27781aad72c5be60",
  measurementId: "G-C86FC2HZYD",
};

export const sentryConfig = {
  dsn: "https://5d2859d8570c4de9aea385cc6abc162b@o406775.ingest.sentry.io/6612939",
};
