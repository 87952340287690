import { Box, ThemeProvider } from "@mui/material";
import { getAnalytics } from "firebase/analytics";
import { connectAuthEmulator, getAuth } from "firebase/auth";
import { connectDatabaseEmulator, getDatabase } from "firebase/database";
import { connectFirestoreEmulator, getFirestore } from "firebase/firestore";
import { connectStorageEmulator, getStorage } from "firebase/storage";
import React, { useEffect } from "react";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  AnalyticsProvider,
  AuthProvider,
  DatabaseProvider,
  FirestoreProvider,
  StorageProvider,
  useFirebaseApp,
} from "reactfire";
import { RecoilRoot } from "recoil";
import { AppRoutes } from "src/components/nav/AppRoutes";
import { theme } from "./theme";
import { isPortReachable } from "./utils/emulators.utils";

export default function App() {
  const app = useFirebaseApp();
  const analytics = getAnalytics(app);
  const auth = getAuth(app);
  const databaseInstance = getDatabase(app);
  const firestoreInstance = getFirestore(app);
  const storageInstance = getStorage(app);

  useEffect(() => {
    const connectToEmulators = async () => {
      const host = "127.0.0.1";

      // TODO: get dynamically
      const authPort = 9099;
      const databasePort = 9000;
      const firestorePort = 8080;
      const storagePort = 9199;

      const isAuthEmulatorRunning = await isPortReachable(authPort);
      if (!isAuthEmulatorRunning) return;
      const isFirestoreEmulatorRunning = await isPortReachable(firestorePort);
      if (!isFirestoreEmulatorRunning) return;

      connectAuthEmulator(auth, `http://${host}:${authPort}`);
      connectDatabaseEmulator(databaseInstance, host, databasePort);
      connectFirestoreEmulator(firestoreInstance, host, firestorePort);
      connectStorageEmulator(storageInstance, host, storagePort);
    };

    if (process.env.NODE_ENV !== "development") return;
    connectToEmulators();
  }, []);

  return (
    <AnalyticsProvider sdk={analytics}>
      <AuthProvider sdk={auth}>
        <DatabaseProvider sdk={databaseInstance}>
          <FirestoreProvider sdk={firestoreInstance}>
            <StorageProvider sdk={storageInstance}>
              <RecoilRoot>
                <BrowserRouter>
                  <ThemeProvider theme={theme}>
                    <Box
                      sx={{
                        height: "100%",
                        width: "100%",
                        overflow: "auto",
                      }}
                    >
                      <AppRoutes />
                      <ToastContainer />
                    </Box>
                  </ThemeProvider>
                </BrowserRouter>
              </RecoilRoot>
            </StorageProvider>
          </FirestoreProvider>
        </DatabaseProvider>
      </AuthProvider>
    </AnalyticsProvider>
  );
}
